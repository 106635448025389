<template>
  <Sidebar :loading="!!loading"
           :instance="instance"
           @close="$router.push({name: 'client.index'})"
  >
    <template v-if="instance">
      <ClientLayout>
        <template v-slot:avatar>
          <AvatarEditable :layout="{rounded: true}" size="92" :subject="instance" v-model="image"></AvatarEditable>
        </template>
        <template v-slot:company-name>
          <v-text-field
              outlined dense flat placeholder="Company name"
              class="text-h4 font-weight-bold" height="2em"
              :error-messages="getErrors('company.name')"
              :hide-details="!hasErrors('company.name')"
              @change="unsetError('company.name')"
              v-model="company_name"></v-text-field>
        </template>
        <template v-slot:name>
          <v-text-field
              outlined dense flat placeholder="Full name"
              class="text-h6 font-weight-bold" height="1.5em"
              :error-messages="getErrors('name')"
              :hide-details="!hasErrors('name')"
              @change="unsetError('name')"
              v-model="name"></v-text-field>
        </template>
        <template v-slot:phone>
          <v-text-field
              v-mask="'+#(###)#########?#'"
              outlined dense flat placeholder="Phone number"
              :error-messages="getErrors('phone')"
              :hide-details="!hasErrors('phone')"
              @change="unsetError('phone')"
              v-model="phone"></v-text-field>
        </template>
        <template v-slot:email>
          <v-text-field
              type="email"
              outlined dense flat placeholder="Email"
              :error-messages="getErrors('email')"
              :hide-details="!hasErrors('email')"
              @change="unsetError('email')"
              v-model="email"></v-text-field>
        </template>
        <template v-slot:login>
          <v-text-field id="login" background-color="white" class="transparent" filled dense flat
                        :hide-details="!hasErrors('login')"
                        :error-messages="getErrors('login')"
                        @change="unsetError('login')"
                        v-model="login"></v-text-field>
        </template>
        <template v-slot:password>
          <v-text-field id="password" background-color="white" class="transparent" filled dense flat
                        :hide-details="!hasErrors('password')"
                        :error-messages="getErrors('password')"
                        @change="unsetError('password')"
                        v-model="password"></v-text-field>
        </template>
      </ClientLayout>

      <v-spacer></v-spacer>

      <v-container fluid class="px-10">
        <v-row>
          <v-col md="6">
            <ManagerPicker v-model="company_manager"></ManagerPicker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex flex-grow-1 flex-nowrap align-center">
            <v-btn large color="secondary" :to="{name: 'client.index'}" plain>Cancel</v-btn>
            <v-btn large color="success" :loading="!!loading" @click="save()" depressed>Save</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </Sidebar>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Sidebar from "../../components/layout/Sidebar";
import {mapFields} from "vuex-map-fields";
import ManagerPicker from "../../components/common/ManagerPicker";
import AvatarEditable from "../../components/common/AvatarEditable";
import ClientLayout from "../../components/layout/ClientLayout";

export default {
  name: "ClientViewer",
  components: {ClientLayout, AvatarEditable, ManagerPicker, Sidebar},
  props: [
    'uuid'
  ],
  computed: {
    ...mapState('client/instance', {
      instance: 'instance',
      loading: 'loading',
    }),
    ...mapFields('client/instance', {
      name: 'instance.name',
      phone: 'instance.phone',
      email: 'instance.email',
      login: 'instance.login',
      password: 'instance.password',
      company_name: 'instance.company.name',
      company_manager: 'instance.company.manager',
      image: 'instance.image',
    }),
    ...mapGetters('error', {
      hasErrors: 'hasErrors',
      getErrors: 'getErrors',
    }),
  },
  methods: {
    ...mapActions('client/instance', {
      save: 'save',
      cancel: 'cancel',
    }),
    ...mapActions('error', {
      unsetError: 'unset',
      clearErrors: 'clear',
    }),
  },

  beforeDestroy: function () {
    this.clearErrors();
  },
}
</script>