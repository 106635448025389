<template>
    <v-autocomplete
        class="manager-picker"
        filled
        flat
        :value="value"
        @change="v => $emit('input', v)"
        :filter="filter"
        :loading="loading"
        :items="items"
        :return-object="true"
        item-text="text"
        item-value="uuid"
        append-icon=""
        hide-details
        placeholder="Choose manager"
        clearable
        :open-on-clear="true"
        :allow-overflow="false"
    >
      <template v-slot:selection="{item, select, selected, attrs}">
        <v-list-item @click="select" v-bind="attrs" class="px-0">
          <v-list-item-avatar size="60">
            <Avatar size="60" :subject="item"></Avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>Assigned manager</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
      </template>
      <template v-slot:item="{item}">
        <template v-if="typeof item !== 'object'">
          <v-list-item-content v-text="item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
</template>

<script>
import http from '../../http/api';
import {map} from "lodash";
import {mapActions, mapState} from "vuex";
import Avatar from "../common/Avatar";

export default {
  name: "ManagerPicker",
  components: {Avatar},
  props: {
    value: {
      default: () => null
    },
  },
  computed: {
    ...mapState('registry', {
      items: 'team',
      loading: 'loading.team'
    }),
  },
  methods: {
    ...mapActions('registry', {
      load: 'load'
    }),
    filter: (item, queryText, itemText) => {
      // TODO Complete filters
      let subject = item.name ? item.name : '';
      return (
          subject.replace(/[^\d|\w]+/g, '').toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
          itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }
  },
  mounted() {
    if (this.items.length === 0) this.load('team');
  }
}

</script>

<style lang="css">
.v-input.manager-picker.v-input--is-focused.v-select--is-menu-active div.v-input__control div.v-input__slot div.v-select__slot div.v-select__selections input {
  /* OK */
}
.v-input.manager-picker.v-input--is-dirty.v-input--is-focused.v-select--is-menu-active div.v-input__control div.v-input__slot div.v-select__slot div.v-select__selections > .d-flex + input {
  max-width: 0 !important;
  min-width: 0 !important;
}
</style>