var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"manager-picker",attrs:{"filled":"","flat":"","value":_vm.value,"filter":_vm.filter,"loading":_vm.loading,"items":_vm.items,"return-object":true,"item-text":"text","item-value":"uuid","append-icon":"","hide-details":"","placeholder":"Choose manager","clearable":"","open-on-clear":true,"allow-overflow":false},on:{"change":function (v) { return _vm.$emit('input', v); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._b({staticClass:"px-0",on:{"click":select}},'v-list-item',attrs,false),[_c('v-list-item-avatar',{attrs:{"size":"60"}},[_c('Avatar',{attrs:{"size":"60","subject":item}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v("Assigned manager")])],1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(typeof item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item)}})]:[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(item.name))])],1)]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }